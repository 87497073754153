import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
    root: {
        fontFamily: 'MuseoSans, sans-serif',
        color: theme.palette.primary.main,
        marginTop: '237px',
        marginLeft: '25%',
        marginBottom: '300px',
        width: '58%',
        '& #first': {
            marginTop: '57.45px',
            marginBottom: '57.45px',
            fontWeight: 300,
            fontSize: '37.24px',
            letterSpacing: '1.5px',
            textAlign: 'left',
            color: '#3D4274',
            lineHeight: '51.55px',
        },
        [theme.breakpoints.up(1441)]: {
            maxWidth: '835px',
            margin: '237px auto 250px auto',
        },
        [theme.breakpoints.down(1200)]: {
            width: '70.83%',
            marginBottom: '200px',
        },
        [theme.breakpoints.down(769)]: {
            marginLeft: '22.13%',
            '& #first': {
                fontSize: '36px',
                letterSpacing: '1.26px',
                lineHeight: '51px',
            },
        },
        [theme.breakpoints.down(701)]: {
            marginBottom: '100px',
            '& #first': {
                fontSize: '30px',
                letterSpacing: '1.31px',
                lineHeight: '45px',
            },
        },
        [theme.breakpoints.down(650)]: {
            marginTop: '150px',
            '& #first': {
                marginTop: '50px',
                fontSize: '25px',
                letterSpacing: '1px',
                lineHeight: '40px',
            },
        },
        [theme.breakpoints.down(501)]: {
            marginLeft: '21.85%',
            marginTop: '125px',
            '& #first': {
                marginTop: '45px',
                fontSize: '17.14px',
                letterSpacing: '0.75px',
                lineHeight: '25.71px',
            },
        },
    },
    title: {
        fontWeight: 300,
        fontSize: '70px',
        letterSpacing: '7px',
        marginBottom: '0',
        [theme.breakpoints.down(769)]: {
            letterSpacing: '9.5px',
        },
        [theme.breakpoints.down(701)]: {
            fontSize: '67px',
            letterSpacing: '6.92px',
        },
        [theme.breakpoints.down(650)]: {
            fontSize: '50px',
            letterSpacing: '4px',
        },
        [theme.breakpoints.down(501)]: {
            fontSize: '35px',
            letterSpacing: '3.95px',
        },
    },
    titleSecondPart: {
        marginLeft: '100px',
        [theme.breakpoints.down(650)]: { marginLeft: '80px', },
        [theme.breakpoints.down(501)]: { marginLeft: '60px', },
    },
});
