// React imports
import React from 'react';

// UI – style imports
import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './AboutUs.style';

function AboutUs(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>

            <p className={classes.title}>
                El nuevo sendero
            </p>
            <p className={clsx(classes.title, classes.titleSecondPart)}>
                de nuestra travesía
            </p>

            <p id='first'>
                Tarjetas Cuenca se trata de una sociedad con la cual te
                ofrecemos una tarjeta de crédito, que te brinda nuevos y
                mejores servicios pensados en tus necesidades. ¡Cada paso
                es un paso más hacia ti!
            </p>

        </Box>
    );
}

export default withStyles(styles)(AboutUs);
