import React from 'react'

import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import SEO from 'components/seo';
import WithTheme from 'components/WithTheme';
import homeHero from 'images/home_hero.jpg';
import homeHeroMob from 'images/home_hero_mob.jpg';
import Layout from 'views/Layout';
import AboutUs from 'views/Index/AboutUs';
import Benefits from 'views/Index/Benefits';

const IndexPage = () => {
  const mobile = useMediaQuery('(max-width:500px)')
  return (
    <WithTheme>
      <Layout>

        <SEO title='Tarjetas Cuenca' />

        <AboutUs />

        <Box>
            <img style={{width: "100%"}} src={mobile ? homeHeroMob : homeHero} alt='Tarjetas Cuenca' />
        </Box>

        <Benefits />

      </Layout>
    </WithTheme>
  )
}

export default IndexPage
