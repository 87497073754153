// React imports
import React from 'react';

// UI – style imports
import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Benefits.style';

function Benefits(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
      <Box className={classes.container}>
        <Box className={clsx(classes.root, classes.marginLeft)}>

            <p id='first'>
                Nuevos caminos con los que obtienes
            </p>
            <p className={classes.title}>
                grandes
            </p>
            <p className={clsx(classes.title, classes.titleSecondPart)}>
                beneficios
            </p>

        </Box>

        <Box className={classes.root}>
          <p id='second'>
            Con Tarjetas Cuenca te ofreceremos: generar un historial crediticio,
             incrementar los establecimientos donde podrás usar la tarjeta,
             acceder a la renta de autos, reservaciones en hoteles, agregar
             más opciones de pagos y más.
          </p>
        </Box>
      </Box>
    );
}

export default withStyles(styles)(Benefits);
